<template>
    <div id="profile">
      <div class="card">
        <div class="card-body">

            <h4 class="header-title mb-4">{{$t('profile.completaIlProfilo')}}</h4>

            <div class="row">
                <!-- <div class="col-md-4">
                    <div>
                        <form action="#" class="dropzone dz-clickable">

                            <div class="dz-message needsclick">
                                <div class="mb-3">
                                    <i class="display-4 text-muted mdi mdi-cloud-upload-outline"></i>
                                </div>
                                <h4>Cambia la tua foto profilo</h4>
                            </div>
                        </form>
                    </div>
                </div> -->

                <div class="col-12 px-4">
                    <div class="form-group row">
                        <label class="col-md-2 col-form-label text-uppercase">{{$t('profile.idUtente')}}</label>
                        <div class="col-md-8">
                            <span class="form-control bg-soft-dark">{{profile.id_utente_concorsando}}</span>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="example-email-input" class="col-md-2 col-form-label">{{$t('profile.email')}}</label>
                        <div class="col-md-8">
                            <input :class="[((profile.autenticazioneVO.social === 'concorsando') ? 'bg-soft-dark' : ''), 'form-control']" type="email" v-model.trim="$v.email.$model" id="example-email-input" :disabled="profile.autenticazioneVO.social === 'concorsando'">
                            <div class="error pl-vertical-align-input custom-font" v-if="!$v.email.email">{{$t('profile.erroreFormatoEmail')}}</div>
                        </div>
                        <div class="col-md-2" v-if="profile.email_verificata">
                            <span><i class="mdi mdi-check-decagram form-control border-0 px-0 text-success"> {{$t('profile.verificata')}}</i></span>
                        </div>
                        <div class="col-md-2" v-else>
                            <button type="button" :class="[(!isVerifyAllowed || (profile.email_comunicazioni !== email || profileUpdated) ? 'cursorNotAllowed' : ''), 'btn btn-danger w-100']" :disabled="!isVerifyAllowed || (profile.email_comunicazioni !== email || profileUpdated)" @click="verifyEmail()" v-if="emailSent === false">{{$t('profile.verifica')}}</button>
                            <span v-else><i class="mdi mdi-check form-control border-0 px-0 text-success"> {{$t('profile.emailInviata')}}</i></span>
                        </div>
                    </div>

                    <!-- stato emial -->

                    <div class="form-group row">
                        <label class="col-md-2 col-form-label">{{$t('profile.account')}}</label>
                        <div class="col-md-8">
                            <span class="form-control text-uppercase bg-soft-dark" v-if="profile.livello === 1">{{$t('profile.premium')}}</span>
                            <span class="form-control text-uppercase bg-soft-dark" v-else>{{$t('profile.demo')}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-2 col-form-label">{{$t('profile.haiEffettuatoAccessoCon')}}</label>
                        <div class="col-md-8">
                            <span class="form-control text-capitalize bg-soft-dark">{{authentications.social}}</span>
                        </div>
                    </div>

                    <!-- <div class="form-group row">
                        <label for="example-text-input" class="col-md-2 col-form-label">Nome</label>
                        <div class="col-md-10">
                            <input class="form-control" type="text" value="Artisanal kale" id="example-text-input">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="example-text-input" class="col-md-2 col-form-label">Cognome</label>
                        <div class="col-md-10">
                            <input class="form-control" type="text" value="Artisanal kale" id="example-text-input">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="example-password-input" class="col-md-2 col-form-label">Password</label>
                        <div class="col-md-10">
                            <input class="form-control" type="password" value="hunter2" id="example-password-input">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-2 col-form-label">Argomenti</label>
                        <div class="col-md-10">
                            <select class="form-control">
                                <option>Diritto</option>
                                <option>Comune</option>
                                <option>Matematica</option>
                            </select>
                        </div>
                    </div> -->

                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-model="flagCommerciale" id="invalidCheck">
                            <label class="custom-control-label" for="invalidCheck">
                                {{$t('profile.flagCommerciale')}}
                            </label>
                        </div>
                    </div>

                    <div class="row">
                        <div :class="(this.profile.autenticazioneVO !== undefined && this.profile.autenticazioneVO.social === 'concorsando') ? 'col' : 'col-md-3'"></div>
                        <div class="col-md-3 p-1">
                            <button :class="[(verifyIfProfileIsEdited() ? 'cursorNotAllowed' : ''), 'btn btn-primary w-100']" type="button" @click="updateProfile()" :disabled="verifyIfProfileIsEdited()">
                                <i class="mdi mdi-content-save"></i>
                                {{$t('profile.salvaModifiche')}}
                            </button>
                        </div>
                        <div class="col-md-3 p-1">
                            <button class="btn btn-danger w-100" type="button" data-toggle="modal" data-target="#modalConfirm">
                                <i class="mdi mdi-delete-forever"></i>
                                {{$t('profile.eliminaProfilo')}}
                            </button>
                        </div>
                        <div class="col-md-3 p-1" v-if="this.profile.autenticazioneVO !== undefined && this.profile.autenticazioneVO.social === 'concorsando'">
                            <button class="btn btn-primary w-100" type="button" data-toggle="modal" data-target="#modalChangePassword">
                                <i class="mdi mdi-refresh"></i>
                                {{$t('profile.cambiaPassword')}}
                            </button>
                        </div>
                        <div :class="(this.profile.autenticazioneVO !== undefined && this.profile.autenticazioneVO.social === 'concorsando') ? 'col' : 'col-md-3'"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>

      <div class="card" v-if="!isLifetimePremium">
        <div class="card-body">
          <h4 class="header-title mb-4">{{$t('profile.gestisciPremium')}}</h4>
          <div class="row" v-if="endDate">
            <div class="col-md-12">
              <p v-if="computeEndPremiumDate()">{{$t('profile.premiumScade')}} {{formatData()}}.</p>
              <p v-else>{{$t('profile.premiumScaduto')}} {{formatData()}}.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p v-if="isPremium">{{$t('profile.rinnovaPremium')}}</p>
              <p v-else>{{$t('profile.acquistaPremium')}}</p>
               <p class="text-danger">{{$t('profile.messaggioNonAbbonamento')}}</p>
              <pay-pal-payment></pay-pal-payment>
            </div>
          </div>
        </div>
      </div>

        <div class="card" v-if="!!devices.length">
            <div class="card-body">
                <h4 class="header-title mb-4">{{ $t('profile.dispositiviCollegati') }}</h4>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"  v-for="device in devices" :key="device.id"
                        v-bind:class = "(token_api === device.token_api) ?'current-device':''"
                    >
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 mr-2">
                                <i class="mdi mdi-devices font-size-24 mr-1 align-middle"></i>
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-0" v-if="device.dispositivo">{{device.dispositivo}}</h6>
                                <p class="mb-0 text-muted" v-if="device.sistema_operativo">{{device.sistema_operativo}}</p>
                                <p class="mb-0 text-muted" v-if="device.data_creazione">{{ formatDataDispositivo(device.data_creazione)}}</p>
                            </div>
                            <div class="flex-shrink-0 text-end" v-if="token_api !== device.token_api">
                                    <button @click="showModalDeleteDevice(device.id)" type="button"
                                            class="btn btn-outline-danger"
                                            data-toggle="tooltip"
                                            data-placement="top" :title="$t('profile.rimuoviDispositivo')">
                                        <i class="mdi mdi-trash-can"></i>
                                    </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

      <div id="modalConfirm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title mt-0" id="myModalLabelConfirm">{{$t('profile.modale.conferma.titolo')}}</h5>
            </div>
            <div class="modal-body">
              <h5 class="font-size-16">{{$t('profile.modale.conferma.sottotitolo')}}</h5>
              <p>{{$t('profile.modale.conferma.messaggio')}}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.chiudi')}}</button>
              <button type="button" class="btn btn-primary waves-effect waves-light" @click="deleteProfile()" data-dismiss="modal">{{$t('profile.modale.conferma.siCancellami')}}</button>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div>

      <div id="modalError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title mt-0" id="myModalLabel">{{$t('profile.modale.errore.titolo')}}</h5>
            </div>
            <div id="modal-body" class="modal-body">
              <p id="message-body"></p>
            </div>
            <div class="modal-footer">
              <button type="button" id="button-ok" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: 'Home' })">{{$t('generale.annulla')}}</button>
              <button type="button" id="button-retry" class="btn btn-primary waves-effect waves-light d-none" @click="preFilledFields()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div>

        <!-- Modal delete device -->
        <div id="modalDelete" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalDeleteLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalDeleteLabel">{{$t('profile.modale.rimuoviDevice.titolo')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div id="modal-body-delete" class="modal-body">
                        <h5 class="font-size-16">{{$t('profile.modale.rimuoviDevice.messaggio')}}</h5>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" @click="deleteDevice()" data-dismiss="modal">{{$t('generale.conferma')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal delete device -->

      <div id="modalChangePassword" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalChangePasswordLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <form @submit.prevent="changeUserPassword()">
              <div class="modal-header">
                <h5 class="modal-title mt-0" id="modalChangePasswordLabel">{{$t('profile.modale.cambiaPassword.titolo')}}</h5>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label for="old-password">{{$t('profile.modale.cambiaPassword.vecchiaPassword')}}</label>
                  <input type="password" class="form-control" id="old-password" v-model.trim="changePassword.oldPassword" required>
                </div>
                <div class="form-group">
                  <label for="new-password">{{$t('profile.modale.cambiaPassword.nuovaPassword')}}</label>
                  <input type="password" class="form-control" id="new-password" v-model.trim="$v.changePassword.newPassword.$model" required>
                  <div class="error pl-vertical-align-input custom-font" v-if="!$v.changePassword.newPassword.validatePassword">{{$t('profile.modale.cambiaPassword.erroreFormatoPassword')}}</div>
                </div>
                <div class="form-group">
                  <label for="password-confirm">{{$t('profile.modale.cambiaPassword.confermaPassword')}}</label>
                  <input type="password" class="form-control" id="password-confirm" v-model.trim="$v.changePassword.confirmPassword.$model" required>
                  <div class="error pl-vertical-align-input custom-font" v-if="!$v.changePassword.confirmPassword.sameAsPassword">{{$t('profile.modale.cambiaPassword.passwordNonCorrispondono')}}</div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                <button type="submit" class="btn btn-primary waves-effect waves-light">{{$t('generale.conferma')}}</button>
              </div>
            </form>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div>

    </div>


</template>

<script>
    import 'bootstrap'
    import $ from 'jquery'
    import { mapGetters, mapActions } from 'vuex'
    import { MODULES } from "@/store/modules-enum";
    import { PROFILE_ACTIONS } from "@/store/profile/actions-enum";
    import { AUTH_GETTERS } from "@/store/auth/getters-enum";
    import { AUTH_ACTIONS } from "@/store/auth/actions-enum";
    import _ from 'lodash'
    import {email, sameAs} from 'vuelidate/lib/validators'
    import {validatePassword} from "@/helpers/utilities";
    import Swal from "sweetalert2";
    import PayPalPayment from "@/components/PayPalPayment";
    import moment from 'moment';
    import CustomUtenteApi from "@/lib/CustomConcorsandoClient/api/CustomUtenteApi";
    import {promisify} from "../lib/utils";
    const utenteClient = new CustomUtenteApi()

    export default {
        name: "Profile",
        components: {PayPalPayment},
        data () {
            return {
                email: '',
                flagCommerciale: false,
                emailSent: false,
                profileUpdated: false,
                changePassword: {
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                },
                devices: [],
                idSelectedDevice: ''
            }
        },
        validations: {
            email: {
                email
            },
            changePassword: {
                newPassword: {
                    validatePassword
                },
                confirmPassword: {
                    sameAsPassword: sameAs('newPassword')
                }
            }
        },
        computed: {
            ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.PROFILE, AUTH_GETTERS.AUTHENTICATIONS, AUTH_GETTERS.TOKEN_API]),
            isVerifyAllowed: function() {
                return !_.isEmpty(this.email);
            },
            endDate: function() {
                const date = _.get(this.profile, "data_scadenza_premium");
                if (date) {
                    const momentDate = moment(date);
                    return momentDate.isValid() ? momentDate : null
                }
                else {
                    return null;
                }
            },
            isPremium: function() {
                return _.get(this.profile, "livello") === 1;
            },
            isLifetimePremium: function() {
                return this.isPremium && this.endDate == null;
            }
        },
        watch: {
            email: function() {
                this.verifyIfProfileIsEdited()
            },
            flagCommerciale: function() {
                this.verifyIfProfileIsEdited()
            }
        },
        mounted() {
            this.backToTop()
            this.preFilledFields()
            this.getDeviceUser();
        },
        methods: {
            ...mapActions(MODULES.PROFILE, [PROFILE_ACTIONS.UPDATE_USER, PROFILE_ACTIONS.SEND_VERIFY_EMAIL, PROFILE_ACTIONS.DELETE_USER, PROFILE_ACTIONS.GET_USER, PROFILE_ACTIONS.UPDATE_PASSWORD, PROFILE_ACTIONS.REMOVE_DEVICE]),
            ...mapActions(MODULES.AUTH, [AUTH_ACTIONS.LOGOUT]),
            updateProfile () {
                this.$v.$touch()
                if (!this.$v.$invalid && !this.verifyIfProfileIsEdited()) {
                    this.$emit('is-components-ready', 'profile', false)
                    const context = this
                    const body = _.cloneDeep(this.profile)
                    body.email_comunicazioni = (this.profile.autenticazioneVO.social === 'concorsando') ? this.profile.email_comunicazioni : this.email
                    body.flag_privacy = (this.flagCommerciale) ? 2 : 1
                    this.updateUser(body)
                        .then(() => {
                            this.getCurrentUser(true)
                            this.emailSent = false
                            this.profileUpdated = true
                        })
                        .catch(() => {
                            context.showModalError('profile.modale.errore.aggiornamentoProfilo')
                        })
                }
            },
            deleteProfile () {
                this.$emit('is-components-ready', 'profile', false)
                const context = this
                this.deleteUser()
                    .then(() => {
                        context.$emit('is-components-ready', 'profile', true)
                        context.logout({ clearUserData: false })
                        context.$router.push({name: 'Auth', query: { type: 'login' }})
                    })
                    .catch(() => {
                        context.showModalError('profile.modale.errore.cancellaAccount')
                    })
            },
            preFilledFields () {
                this.$emit('is-components-ready', 'profile', false)
                this.getCurrentUser()
            },
            verifyEmail () {
                if (this.isVerifyAllowed && (this.profile.email_comunicazioni === this.email && !this.profileUpdated)) {
                    this.$emit('is-components-ready', 'profile', false)
                    const context = this
                    this.sendVerifyEmail({
                        email: this.email,
                        id_utente_concorsando: this.profile.id_utente_concorsando
                    })
                        .then(() => {
                            this.emailSent = true
                            this.$emit('is-components-ready', 'profile', true)
                        })
                        .catch(() => {
                            context.showModalError('profile.modale.errore.verifyEmail')
                        })
                }
            },
            getCurrentUser (isSaving) {
                this.$emit('is-components-ready', 'profile', false)
                const context = this
                this.getUser()
                    .then((response) => {
                        $('#button-ok').show()
                        $('#button-retry').hide()
                        context.email = (response.email_comunicazioni !== undefined && response.email_comunicazioni !== null) ? response.email_comunicazioni.trim() : ''
                        context.flagCommerciale = (response.flag_privacy === 2)
                        context.$emit('is-components-ready', 'profile', true)
                    })
                    .catch((error) => {
                        this.$emit('is-components-ready', 'profile', true)
                        if (error !== undefined) {
                            if (error.response !== undefined) {
                                if (error.response.status === 204) {
                                    $('#modalError').modal('show')
                                    $('#message-body').html(context.$t('profile.modale.errore.utenteNonEsistenteConQuestoToken'))
                                    return
                                }
                            }
                        }
                        $('#modalError').modal({backdrop: 'static', keyboard: false})
                        $('#button-ok').hide()
                        $('#button-retry').show()
                        $('#message-body').html(context.$t('profile.modale.errore.profilo'))
                    })
                    .finally(() => {
                        if (isSaving !== undefined && isSaving) {
                            Swal.fire({
                                title: context.$t('profile.sweetAlert.salva.title'),
                                text: context.$t('profile.sweetAlert.salva.text'),
                                icon: 'success',
                                confirmButtonText: context.$t('generale.ok')
                            })
                        }
                    })
            },
            verifyIfProfileIsEdited () {
                if ((this.$v.email.email === false || this.email === '' || this.email === null)
                    || (this.email === this.profile.email_comunicazioni && this.profile.flag_privacy === 1 && !this.flagCommerciale)
                    || (this.email === this.profile.email_comunicazioni && this.profile.flag_privacy === 2 && this.flagCommerciale)
                    || (this.email !== this.profile.email_comunicazioni && this.profile.autenticazioneVO.social === 'concorsando')) {
                    return true
                } else {
                    return false
                }
            },
            changeUserPassword () {
                const context = this
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    this.$emit('is-components-ready', 'profile', false)
                    this.updatePassword({
                        oldPassword: this.changePassword.oldPassword,
                        newPassword: this.changePassword.newPassword
                    })
                        .then(() => {
                            context.$emit('is-components-ready', 'profile', true)
                            Swal.fire({
                                title: this.$t('profile.sweetAlert.passwordCambiata.title'),
                                text: this.$t('profile.sweetAlert.passwordCambiata.text'),
                                icon: 'success',
                                confirmButtonText: this.$t('generale.ok')
                            })
                        })
                        .catch((error) => {
                            context.$emit('is-components-ready', 'profile', true)
                            $('#modalError').modal('show')
                            if (error === 401) {
                                $('#message-body').html(context.$t('profile.modale.errore.vecchiaPasswordNonCorretta'))
                            } else {
                                $('#message-body').html(context.$t('profile.modale.errore.cambiaPassword'))
                            }
                        })
                        .finally(() => {
                            $('#modalChangePassword').modal('hide')
                            context.changePassword.oldPassword = ''
                            context.changePassword.newPassword = ''
                            context.changePassword.confirmPassword = ''
                        })
                }
            },
            backToTop () {
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0
            },
            showModalError (message) {
                this.$emit('is-components-ready', 'profile', true)
                $('#modalError').modal('show')
                $('#message-body').html(this.$t(message))
            },
            computeEndPremiumDate () {
                return moment().isBefore(this.endDate)
            },
            formatData () {
                if (this.endDate && this.endDate.isValid()) {
                    return this.endDate.locale('it').format('DD/MM/YYYY')
                }
                else {
                    return ""
                }
            },
            getDeviceUser () {
                const context = this
                this.$emit('is-components-ready', 'profile', false)
                promisify((...args) => utenteClient.getDeviceUser(...args), undefined)
                    .then((res) => {
                        context.$emit('is-components-ready', 'profile', true)
                        context.devices = res || [];
                    })
                    .catch((e) => {
                        console.error(e)
                    })
            },
            showModalDeleteDevice(id){
                this.idSelectedDevice = id
                $('#modalDelete').modal('show')
            },
            formatDataDispositivo(data) {
                return moment(data, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
            },
            deleteDevice(){
                this.$emit('is-components-ready', 'profile', false)
                const context = this
                this.removeDevice({
                    id: this.idSelectedDevice
                })
                    .then(() => {
                        context.$emit('is-components-ready', 'profile', true)
                        context.getDeviceUser();
                    })
                    .catch(() => {
                        context.showModalError('profile.modale.rimuoviDevice.errore')
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        .pl-vertical-align-input {
            padding-left: 13px;
        }

        .custom-font {
            font-size: 0.85em;
            line-height: 1.1;
        }
    }
    .current-device {
      background-color: #b0bcef3b !important
    }
</style>
